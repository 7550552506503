<template>
  <div class="row gx-2">
    <div class="col">
      <apex-chart width="100%" type="bar" :options="graphOptions" :series="series"></apex-chart>
    </div>
  </div>
</template>

<script>
import ApexChart from 'vue-apexcharts'

export default {
  components: {
    ApexChart
  },
  props: {
    graphOptions: {
      default: []
    },
    series: {
      type: Array
    }
  }
}
</script>
