<template>
  <div class="card card-xs-full">
    <div class="card-body pb-0">
      <div class="hstack">
        <h5 class="mb-5">Power Consumption- KW/h</h5>
      </div>

      <div class="row gx-2">
        <div class="col">
          <apex-chart width="100%" type="bar" :options="graphOptions" :series="series"></apex-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApexChart from 'vue-apexcharts'

export default {
  components: {
    ApexChart
  },
  props: {
    graphOptions: {
      default: []
    },
    series: {
      type: Array
    }
  }
}
</script>
