<template>
  <div class="container">
    <div class="card card-xs-full" v-if="!this.viewAll">
      <div class="card-body pb-0">
        <div class="hstack mb-5">
          <h5 class="mb-0">Power Consumption - Amps</h5>
          <div class="ms-auto">
            <power-consumption-list-filters v-model="params" />
          </div>
        </div>
        <power-consumption-amps-list :graphOptions="graphOptions('Amps')" :series="ampsSeries" />
      </div>
    </div>
    <power-consumption-kwh-list :graphOptions="graphOptions('Kw/h')" :series="kwhSeries" v-if="!this.viewAll" />
    <power-consumption-logs-list :viewAll="viewAll" @setAll="setAll" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import swal from 'sweetalert'
import { helperComputed, helperMethods } from '@/vuex/modules/screens/consumption-logs'
import { compareQueryParams } from '@codium/codium-vue'
import PowerConsumptionAmpsList from './PowerConsumptionAmpsList.vue'
import PowerConsumptionKwhList from './PowerConsumptionKwhList.vue'
import PowerConsumptionListFilters from './PowerConsumptionListFilters.vue'
import PowerConsumptionLogsList from './PowerConsumptionLogsList.vue'

export default {
  components: {
    PowerConsumptionAmpsList,
    PowerConsumptionKwhList,
    PowerConsumptionListFilters,
    PowerConsumptionLogsList
  },

  data () {
    return {
      params: {
        startDate: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      viewAll: false
    }
  },
  computed: {
    ...helperComputed,
    kwhSeries () {
      if (this.consumptionLogs) {
        return [{
          data: this.consumptionLogs.map((item) => {
            return {
              x: item.period,
              y: item.average_power_consumption_kwh
            }
          })
        }]
      }

      return []
    },
    ampsSeries () {
      if (this.consumptionLogs) {
        return [{
          data: this.consumptionLogs.map((item) => {
            return {
              x: item.period,
              y: item.average_power_consumption_amps
            }
          })
        }]
      }

      return []
    },
    labels () {
      if (!this.consumptionLogs) {
        return []
      }
      return this.consumptionLogs.map(log => log.period)
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      if (this.params.endDate && this.params.startDate) {
        try {
          await this.getConsumptionLogs({ params, fresh: true })
        } catch (err) {
          await swal({
            title: 'Invalid date range.',
            text: err.response.data.message,
            buttons: {
              confirm: { text: 'Close' }
            }
          })
          throw err
        }
      }
    })
  },

  beforeDestroy () {
    this.resetConsumptionLogs()
  },
  async created () {
    if (this.params.endDate && this.params.startDate) {
      await this.getConsumptionLogs({ params: this.params })
    }
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        if (this.params.endDate && this.params.startDate) {
          await this.getConsumptionLogs({ params })
        }

        done()
      } catch {
        done(true)
      }
    },
    setAll (value) {
      this.viewAll = value
    },

    graphOptions (label) {
      return {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false // This will remove the download/export options
          },
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.15,
            }
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false,
          position: 'top'
        },
        stroke: {
          show: false
        },
        xaxis: {
          categories: this.labels
        },
        yaxis: {
          show: false,
        },
        fill: {
          type: ['solid'],
          opacity: 1,
          colors: ['#dee2e6', '#464646']
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '14px',
            backgroundColor: '#464646 ',
            color: '#fff',
          },
          x: {
            show: false,
          },
          y: {
            formatter: function (val) {
              return `${val} ${label}`
            },
            title: {
              formatter: () => '',
            },
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: false,
          },
          theme: 'dark',
          fillSeriesColor: false,
        }
      }
    }
  }
}
</script>
