<template>
  <div class="input-group input-group-outline">
    <div class="d-flex align-items-center mb-2">
      <button
        class="btn"
        :class="isHourly ? 'btn-primary' : 'btn-outline-primary'"
        @click="toggleMode(true)"
      >
        Hourly
      </button>
      <button
        class="btn ms-2"
        :class="!isHourly ? 'btn-primary' : 'btn-outline-primary'"
        @click="toggleMode(false)"
      >
        Date Range
      </button>
    </div>
    <div class="input-group input-group-outline">
      <label class="input-group-text">
        {{ isHourly ? 'Single Date' : 'Date Range' }}
      </label>
      <div v-if="isHourly">
        <c-date style="max-width: 250px;" v-model="dateRange.startDate" placeholder=""  @input="onDateChange"/>
      </div>
      <div v-else>
        <input-date-range
          v-model="dateRange"
          class="form-control"
          :single-date="isHourly"
          @change="onDateChange"
        />
      </div>

    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import swal from 'sweetalert'
import { useQueryParamFilters } from '@codium/codium-vue'
import InputDateRange from '@/components/forms/InputDateRange'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    InputDateRange
  },

  data () {
    return {
      isHourly: false,
      dateRange: {
        startDate: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      filters: {
        startDate: null,
        endDate: null
      },
      showRangeError: false
    }
  },
  watch: {
    dateRange: {
      async handler (date) {
        if (!date?.startDate || !date?.endDate) {
          await swal({
            title: 'Invalid date range.',
            text: 'Please set a valid date range.',
            buttons: {
              confirm: { text: 'Close' }
            }
          })
          return
        }

        this.filters.startDate = date?.startDate
        this.filters.endDate = date?.endDate
      },
      immediate: true
    }
  },
  methods: {
    toggleMode (toHourly) {
      this.isHourly = toHourly
      if (!toHourly) {
        this.dateRange = {
          startDate: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        }
      } else {
        this.dateRange = {
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        }
      }
    },
    onDateChange (newValue) {
      const range = {
        startDate: newValue,
        endDate: newValue
      }
      this.dateRange = { ...range }
    },
  }
}
</script>
