<template>
  <div>
    <label>Date Range</label>
    <input-date-range
      v-model="dateRange"
      placeholder=""
      class="form-control date-range"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import swal from 'sweetalert'
import { useQueryParamFilters } from '@codium/codium-vue'
import InputDateRange from '@/components/forms/InputDateRange'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    InputDateRange
  },

  data () {
    return {
      dateRange: {
        startDate: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      filters: {
        startDate: null,
        endDate: null
      }
    }
  },
  watch: {
    dateRange: {
      async handler (date) {
        if (!date?.startDate || !date?.endDate) {
          await swal({
            title: 'Invalid date range.',
            text: 'Please set a valid date range.',
            buttons: {
              confirm: { text: 'Close' }
            }
          })

          return
        }

        this.filters.startDate = date?.startDate
        this.filters.endDate = date?.endDate
      },
      immediate: true
    }
  }
}
</script>
