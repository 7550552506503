<template>
  <li>
    <div class="row">
      <div class="col-6 col-lg-8" v-if="!viewAll">
        <p>
          <span class="data-label d-lg-none">Date Time</span>
          {{ log.log_time | date({ format: 'DD/MM/YYYY H:mm:s' })}}
        </p>
      </div>
      <div class="col-6 col-lg-5" v-if="viewAll">
        <p>
          <span class="data-label d-lg-none">Date</span>
          {{ log.log_time | date({ format: 'DD/MM/YYYY' })}}
        </p>
      </div>
      <div class="col-6 col-lg-3" v-if="viewAll">
        <p>
          <span class="data-label d-lg-none">Time</span>
          {{ log.log_time | date({ format: 'H:mm:s'})}}
        </p>
      </div>
      <div class="col-6 col-lg-3">
        <p>
          <span class="data-label d-lg-none">KWH</span>
          {{ log.kwh }}
        </p>
      </div>
      <div class="col-6 col-lg-1">
        <p>
          <span class="data-label d-lg-none">Amps</span>
          {{ log.amps }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>

export default {
  props: {
    log: {
      required: true,
      type: Object
    },
    viewAll: {
      type: Boolean
    }
  },
  computed: {
    logTime () {
      return this.log
    }
  }
}
</script>
