<template>
<div>
  <nav v-if="viewAll" aria-label="breadcrumb" class="mb-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="javascript:void(0)" @click="setViewAll">
            Power Consumption
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Consumption Logs</li>
      </ol>
  </nav>
  <div class="card card-xs-full" v-if="viewAll">
    <div class="card-body">
      <div class="hstack align-items-end">
        <div>
          <power-consumption-logs-list-filters v-if="viewAll" v-model="params" />
        </div>
        <div class="ms-auto">
          <button class="btn btn-outline-primary mt-xl-4" @click="downloadLog('csv')">
            <i class="fa fa-file-csv me-2"></i>
            Download CSV
          </button>
          <button class="btn btn-outline-primary mt-xl-4 ms-2" @click="downloadLog('pdf')">
            <i class="fa fa-file-csv me-2"></i>
            Download Pdf
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-xs-full">
    <div class="card-body pb-0" v-if="!viewAll">
      <div class="hstack mb-5">
        <h5 class="mb-0">Consumption Logs</h5>
        <div class="ms-auto">
          <button @click="setViewAll" class="btn btn-primary">View All</button>
        </div>
      </div>
    </div>
    <div class="card-body pb-0" v-else>
      <div class="hstack mb-3">
        <h5 class="mb-0">Recent Consumption Logs</h5>
      </div>
    </div>
    <c-data-list :items="!viewAll ? logs.slice(0, 10) : logs" @load="load" class="no-links">
      <template #columns>
        <div class="col-lg-8" v-if="!viewAll">
          Date Time
        </div>
        <div class="col-lg-5" v-if="viewAll">
          Date
        </div>
        <div class="col-lg-3" v-if="viewAll">
          Time
        </div>
        <div class="col-lg-3">
          kW
        </div>
        <div class="col-lg-1">
          Amps
        </div>
      </template>
      <template #default="{ item }">
        <logs-list-item :log="item" :viewAll="viewAll"/>
      </template>
    </c-data-list>
  </div>
</div>
</template>

<script>
import { http } from '@/utils'
import swal from 'sweetalert'
import dayjs from 'dayjs'
import { helperComputed, helperMethods } from '@/vuex/modules/screens/logs'
import { compareQueryParams } from '@codium/codium-vue'
import PowerConsumptionLogsListFilters from './PowerConsumptionLogsListFilters.vue'
import LogsListItem from './LogsListItem.vue'

export default {
  components: {
    PowerConsumptionLogsListFilters,
    LogsListItem
  },

  data () {
    return {
      params: {
        startDate: this.viewAll ? dayjs().subtract(6, 'days').format('YYYY-MM-DD') : null,
        endDate: this.viewAll ? dayjs().format('YYYY-MM-DD') : null
      }
    }
  },
  async created () {
    await this.getLogs({ params: this.params, fresh: true })
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getLogs({ params, fresh: true })
    })
  },
  props: {
    viewAll: {
      type: Boolean
    }
  },
  computed: {
    ...helperComputed
  },

  beforeDestroy () {
    this.resetLogs()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getLogs({ params })

        done()
      } catch {
        done(true)
      }
    },
    setViewAll () {
      this.$emit('setAll', !this.viewAll)
    },
    async downloadLog (type) {
      if (!this.params.startDate || !this.params.endDate) {
        await swal({
          title: 'Invalid date range.',
          text: 'Please set a valid date range.',
          buttons: {
            confirm: { text: 'Close' }
          }
        })
        return
      }
      const params = {
        ...this.params,
        type: type
      }

      return http
        .get(`screens/${this.$route.params.screenId}/logs-download`, {
          responseType: 'blob',
          headers: { accept: 'application/json, text/csv' },
          params: params
        })
        .then(response => {
          const filename = `screen-log-consumptions-${this.$route.params.screenId}-${this.params.startDate}-${this.params.endDate}.${type}`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          return response
        })
    },
    watch: {
      viewAll: {
        async handler (val) {
          if (val === true) {
            await this.getLogs({ params: this.params, fresh: true })
          }
        },
        immediate: true
      }
    }
  }
}
</script>
