<template>
  <validation-observer tag="div" class="container container-fluid mt-4 mb-4" @submit.native.prevent ref="validation">
    <div class="card card-xs-full">
      <div class="card-body" @submit.prevent>
        <div class="row">
          <div class="col-lg mb-4 mb-lg-0">
            <img v-bind:src="screen.thumbnail" alt="screen preview" class="screen-thumbnail-lg" v-if="screen.thumbnail">
          </div>
          <div class="col-lg">
            <h3>Details</h3>

            <div class="mb-3">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-sun"></i></div>

                <div class="col">
                  <div class="row">

                    <div class="col-auto">
                      <p class="form-label">Current Brightness</p>
                      <p class="text-muted mb-0">{{ screen.brightness_percentage }}%</p>
                    </div>

                    <div v-if="$isA('admin')" class="col-auto">
                      <p class="form-label">Ambient Brightness</p>
                      <p class="text-muted mb-0">{{ screen.environment_brightness }}</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3" v-if="$isA('admin')">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-sun"></i></div>

                <div class="col">
                  <p class="form-label">Max Brightness (cd/m<sup> 2 </sup>) </p>
                  <c-input type="number" v-model="screenMaxBrightness"/>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-map"></i></div>

                <div class="col">
                  <p class="form-label">Address</p>
                  <p class="text-muted mb-0">{{ screen.address }}</p>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-map-marker-alt"></i></div>

                <div class="col">
                  <p class="form-label">Location</p>
                  <p class="text-muted mb-0">{{ screen.latitude }}, {{ screen.longitude }}</p>
                </div>
              </div>
            </div>

            <div class="mb-3" v-if="$can('write screens')">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-tags"></i></div>
                <div class="col">
                  <p class="form-label">Tags</p>

                  <screen-tag-select holder="All" v-model="stateTags"/>

                </div>
              </div>
            </div>

            <div v-else class="mb-3">
              <div class="row gx-3">
                  <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-tags"></i></div>
                  <div class="col">
                    <p class="form-label mb-0">Tags</p>

                    <div>
                      <div class="chip chip-primary">Tag A</div>
                    </div>
                  </div>
                </div>
            </div>

            <div class="mb-3" v-if="$isA('admin')">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-city"></i></div>
                <div class="col">
                  <p class="form-label">Media Owner</p>
                  <media-owner-select v-model="stateMediaOwnerId"/>
                </div>
              </div>
            </div>
            <div class="mb-3" v-if="$isA('admin')">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="fas fa-sharp fa-regular fa-plug"></i></div>
                <div class="col">
                  <!-- <div class="form-label">Has backup power supplies</div> -->

                  <c-checkbox v-model="stateHasBackupPowerSupplies">Has backup power supplies</c-checkbox>

                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="row gx-3">
                <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-power-off"></i></div>
                <div class="col">
                  <p class="form-label">Screen Operating Hours</p>
                    <div class="d-flex">
                      <div class="col-lg-6 mb-3 pe-3">
                        <label>Time From</label>
                        <flatpickr v-model="screenStartTime" :config="timeConfig" data-field="timeFrom" class="form-control time-single" />
                      </div>
                      <div class="col-lg-6 mb-3 ps-3">
                        <label>Time To</label>
                        <flatpickr v-model="screenEndTime" :config="timeConfig" data-field="timeTo" class="form-control time-single" />
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <validation-provider name="timezone" vid="timezone" rules="required">
                <div class="row gx-3">
                  <div class="col-auto d-flex align-items-center"><i class="far fa-fw fa-lg fa-globe"></i></div>
                  <div class="col">
                    <label class="form-label">Timezone</label>
                    <timezone-select v-model="stateTimezone"/>
                    <c-help-block />
                  </div>
                </div>
              </validation-provider>
            </div>
            <div class="mb-3">
              <validation-provider name="Device ID" vid="device_id">
                <div class="row gx-3">
                  <div class="col-auto d-flex align-items-center" style="width: 40px;">
                    &nbsp;
                  </div>
                  <div class="col">
                    <label class="form-label">Power Consumption - Device ID</label>
                    <c-input type="text" v-model="stateDeviceId"/>
                    <c-help-block />
                  </div>
                </div>
              </validation-provider>
            </div>
          </div>
        </div>
        <div v-if="$can('write screens')" class="text-end">
          <c-button type="submit" @click="save" validate>Save</c-button>
        </div>
      </div>
    </div>
    </validation-observer>
</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/screens'
import { get } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'
import dayjs from 'dayjs'
import MediaOwnerSelect from '@/components/media-owners/MediaOwnerSelect'
import ScreenTagSelect from '@/components/forms/ScreenTagSelect'
import TimezoneSelect from '@/components/forms/TimezoneSelect'

export default {
  mixins: [
    stateMapper
  ],

  data () {
    return {
      selectedTags: [],
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        allowInput: false,
        minuteIncrement: 1,
        onChange: (times, timeString, instance) => {
          const field = instance.element.dataset.field
          const formattedTime = dayjs(timeString, 'h:mm A').isValid() ? dayjs(timeString, 'h:mm A').format('HH:mm:ss') : null
          const value = { ...this.user, [field]: formattedTime }

          this.$emit('update', this.index, value)
        }
      },
    }
  },

  components: {
    MediaOwnerSelect,
    ScreenTagSelect,
    TimezoneSelect
  },

  computed: {
    ...helperComputed,
    ...get('screens', ['screen']),

    screenMaxBrightness: {
      get () {
        return this.stateMaxBrightness
      },
      set (value) {
        this.stateMaxBrightness = value
      }
    },

    screenStartTime: {
      get () {
        return this.stateStartTime
      },
      set (value) {
        this.stateStartTime = value
      }
    },

    screenEndTime: {
      get () {
        return this.stateEndTime
      },
      set (value) {
        this.stateEndTime = value
      }
    }
  },

  methods: {
    ...helperMethods,

    async save () {
      await this.updateScreen({
        id: this.screen.id,
        payload: {
          max_brightness: this.screenMaxBrightness,
          media_owner_id: this.stateMediaOwnerId,
          tags: this.stateTags,
          has_backup_power_supplies: this.stateHasBackupPowerSupplies,
          start_time: this.screenStartTime,
          end_time: this.screenEndTime,
          timezone: this.stateTimezone,
          device_id: this.stateDeviceId
        }
      })
      toast('Successfully updated screen', 'success')
    },

  }
}
</script>
